// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import * as bootstrap from "bootstrap";
import "stylesheets/application"

window.bootstrap = bootstrap

//import $ from 'jquery';
import {email_copy} from './main';
window.email_copy = email_copy

function onClickImage(element) {
  document.getElementById("imgInImageModal").src = element.dataset.full;
  document.getElementById("nameInImageModal").innerHTML = element.title;
  document.getElementById("imageModal").style.display = "block";
}

window.onClickImage = onClickImage;

function scrollToTop() {
  window.scroll({top: 0, left: 0, behavior: 'smooth'});
}
function show_welcom(){
  var welcomModal = new bootstrap.Modal(document.getElementById('welcomModal'), {})
    welcomModal.show();
}

window.show_welcom = show_welcom;

import {getCookie, setCookie,deleteCookie} from "./cookie";
window.getCookie =getCookie;
window.setCookie=setCookie;
window.deleteCookie = deleteCookie;

import {playAfterBack,handleFirstPlay} from "./audio";
window.handleFirstPlay = handleFirstPlay;
window.playAfterBack = playAfterBack;
