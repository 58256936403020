import * as bootstrap from 'bootstrap'

export function email_copy(blok) {
  var element = blok.querySelector("span");
  var tooltip = new bootstrap.Tooltip(element, {})
  tooltip.show();
  var temp = document.createElement("INPUT");
  document.body.appendChild(temp)
  temp.setAttribute("value",element.textContent);
  temp.focus();
  temp.select();
  document.execCommand('copy');
  temp.remove();
  setTimeout((function() {
    tooltip.hide();
  }), 1000);
};
