const playerList = [
  "/muzyka-mira-rumynskaya---narodnaya-3.mp3",
  "/rumynskaya-narodnaya-muzyka-skripka.mp3"
]

function playBase() {
  let player = document.getElementById('player')
  if (player && !isPlaying(player)) {
    player.volume = 0.5;
    if(getCookie("playerListNumber")){
      player.dataset.number = parseInt(getCookie("playerListNumber"))
      player.src = playerList[player.dataset.number]
    }else{
      player.dataset.number = 0;
    }
    if(getCookie("playerCurrentTime")){
      player.currentTime = parseInt(getCookie("playerCurrentTime"));
    }
    window.onpagehide = (event) => {
      setCookie("playerCurrentTime",player.currentTime);
      console.log("unload window")
    }
    // console.log(playerList);
    // player.autoplay = null;
    // player.onplay = null;
    player.onended = (event) => {
      player = event.target
      player.currentTime = 0;
      var current_number = parseInt(player.dataset.number)
      if (current_number + 1 >= playerList.length) {
        player.dataset.number = 0
      } else {
        player.dataset.number = current_number + 1
      }
      setCookie("playerListNumber",player.dataset.number)
      // console.log(player.dataset.number)
      // console.log(playerList[player.dataset.number])
      player.src = playerList[player.dataset.number]
      player.play()
      // console.log("ended");
    }
    player.play().catch(error => {
      let playModal = document.getElementById('playModal')
      var modal = new bootstrap.Modal(playModal, {
        keyboard: false
      })
      playModal.addEventListener("hidden.bs.modal", function() {
        let modal_backdrop = document.querySelector('.modal-backdrop')
        if (modal_backdrop) {
          modal_backdrop.remove()
        }
      })
      modal.show();
    });
  }
}

function isPlaying(audelem) {
  return !audelem.paused;
}

export function playAfterBack() {
  playBase();
  window.addEventListener("click", function() {
    playBase();
  })
  window.addEventListener("scroll", function() {
    playBase();
  })
}

export function handleFirstPlay() {
  playBase();
}
// document.addEventListener("turbolinks:load", function() {
//
//   window.onload = function() {
//
//     if (player && hasPlayed === false) {
//       hasPlayed = true
//       // console.log("window.onload");
//       player.play();
//       // player.src = player.src;
//     }
//   };
//
// });
